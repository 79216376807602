export const columns = [
	{
		prop: 'userName',
		label: '员工姓名',
		width: '150px',
	},
	{
		prop: 'roleName',
		label: '员工角色',
		width: 'auto',
	},
	{
		prop: 'companyName',
		label: '所属公司',
		width: 'auto',
	},
	{
		prop: 'periodMonth',
		label: '数据月份',
		width: '150px',
	},
	{
		prop: 'outCars',
		label: '当月出库台次',
		width: '150px',
	},
	{
		prop: 'outCarsMom',
		label: '当月出库台次环比',
		width: '170px',
	},
]
export const setting = {
	expand: true,
	filters: [
		{
			attr: 'companyId',
			label: '所属公司',
			type: 'select',
			placeholder: '请选择所属公司',
			options: [],
		},
		{
			attr: 'userId',
			label: '员工姓名',
			type: 'select',
			placeholder: '请选择员工姓名',
			options: [],
		},
		{
			attr: 'dataDate',
			value: '',
			shortcuts: [],
			clearable: false,
			label: '数据时间',
			type: 'month',
		},
	],
}
